<template>
  <div>

    <v-card tile class="card_section">
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nazwa
                </th>
                <!-- <th class="text-left">
                  Piorytet
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                @click="open_edit_status(item)"
                :class="item.priority == 'asap'?'acent':''"
                style="cursor: pointer"
              >
                <td>{{ item.name }}</td>
                <!-- <td>{{ item.priority }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      width="750"
      persistent
    >
      <v-card 
        tile
        v-if="$store.state.user_permissions.project_task_update"
      >
        <!-- <v-card-title class="change_card"> -->
          <!-- Zmiana statusu -->
          <v-tabs
            background-color="change_card"
            centered
            dark
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab>Zmiana statusu</v-tab>
            <v-tab>Edytuj</v-tab>

            <v-tab-item>
              
              <v-card-text class="mt-2">
                <h2>{{edited_item.name}}</h2>
                <v-slider
                  class="mt-2"
                  v-model="status"
                  :value="status"
                  :tick-labels="status_options"
                  :max="2"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  color="#333"
                ></v-slider>
                <h3 class="mt-2">Przypisani użytkownicy:</h3>
                <div
                  v-for="item in edited_item.users"
                  :key="item.id"
                >{{item.name}}</div>
                <h3 class="mt-2">Opis</h3>
                {{edited_item.description}}
                <h3 class="mt-2">Przeznaczony czas: {{edited_item.expected_time}}</h3>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="dialog=false"
                >
                  Anuluj
                </v-btn>
                <v-btn
                  color="primary"
                  tile
                  depressed
                  @click="update_status()"
                >
                  Aktualizuj
                </v-btn>
              </v-card-actions>

            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-switch
                    v-model="priority_binary"
                    :label="'Piorytet zadania: '+(priority_binary?'ASAP':'Normalny')"
                    color="primary"
                    inset
                  ></v-switch>

                  <v-text-field
                    v-model="edited_task.name"
                    label="Nazwa"
                    color="primary"
                  ></v-text-field>

                  <v-textarea
                    v-model="edited_task.description"
                    label="Opis"
                    color="primary"
                  ></v-textarea>

                  <v-autocomplete
                    v-model="edited_task.selected_users"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Przypisz pracowników"
                    clearable
                    multiple
                  ></v-autocomplete>

                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="dialog=false"
                  >
                    Anuluj
                  </v-btn>
                  <v-btn
                    color="error"
                    tile 
                    depressed
                    @click="delete_task()"
                    v-if="$store.state.user_permissions.project_task_delete"
                  >
                    Usuń
                  </v-btn>
                  <v-btn
                    color="primary"
                    tile
                    depressed
                    @click="update_task()"
                  >
                    Zapisz zmiany
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

          </v-tabs>
        <!-- </v-card-title> -->

        
      </v-card>

      <!-- Wyglad dla uzytkownikow bez mozliwosci edycji -->
      <v-card 
        v-else
      >
        <v-card-text>
          <h2>Przypisany użytkownik: {{edited_item.assign_user}}</h2>
          <h3>Status: {{status_options[status]}}</h3>
          <h3 class="mt-2">Opis</h3>
          {{edited_item.description}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="dialog=false"
          >
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
// #TODO Nie dziala update - nie poprawny status wyswietla
export default {
  props: {
    title: String,
    items: Array,
    users: Array
  },
  data: () => ({
    dialog: false,
    edited_item: {},
    status: 0,

    status_options: [
      'Nowy',
      'W trakcie',
      'Zakończony'
    ],

    edited_task: {
      name: '',
      description: '',
      priority: false,
      selected_users: []
    },

    priority_binary: 0, // #TODO tymczasowy piorytet poniewaz Zimny zwraca wartosci 'normalny'/'ASAP' zamiast binarki

  }),
  methods: {
    open_edit_status(item){
      // console.log(item); #TODO nie zwraca mi zimny nazwy uzytkownika tylko ID i nie mozna go wyswietlic
      // Aktualizacja statusu
      if(item.status == 'Nowy') this.status = 0;
      else if(item.status == "W trakcie") this.status = 1;
      else this.status = 2;

      // Aktualizowanie danych do zmiany
      this.edited_task.name = item.name;
      this.edited_task.description = item.description;
      this.edited_task.priority = item.priority;
      this.edited_task.selected_users = item.users;

      // #TODO tymczasowy piorytet 
      this.priority_binary = item.priority == 'normalny' ? 0 : 1;

      this.dialog = true;
      this.edited_item = item;
    },
    update_status(){
      this.dialog = false;
      this.$store.commit('loader');
      console.log(this.status);

      let formData = new FormData();
      formData.append("status", this.status_options[this.status]);

      // #TODO tymczasowy piorytet
      console.log('TYMCZASOWY PANIE PRIORITY BINARY')
      console.log(this.priority_binary)
      if(this.priority_binary) this.edited_item.priority = 'ASAP';
      else this.edited_item.priority = 'normalny';

      formData.append("priority", this.edited_item.priority);

      this.$axios({url: this.$store.state.api +'/task/'+this.edited_item.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          // this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Zaaktualizowano zadanie",
            color: "primary",
            btn_color: "white"
          });
          // this.user_data = resp.data.user_assign;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
          // Wymuś pobranie nowych danych
          this.$emit('update_me');
        })
    },
    update_task(){
      this.dialog = false;
      this.$store.commit('loader');
      console.log(this.status);

      let formData = new FormData();
      formData.append("name", this.edited_task.name);
      formData.append("description", this.edited_task.description);
      for(let item in this.edited_task.selected_users)
        formData.append("assign["+item+"]", this.edited_task.selected_users[item]);

      // #TODO tymczasowy piorytet
      if(this.priority_binary) formData.append("priority", 'ASAP');
      else formData.append("priority", 'normalny');

      this.$axios({url: this.$store.state.api +'/task/'+this.edited_item.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          // this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Zaaktualizowano zadanie",
            color: "primary",
            btn_color: "white"
          });
          // this.user_data = resp.data.user_assign;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
          // Wymuś pobranie nowych danych
          this.$emit('update_me');
        })
    },
    delete_task(){
      this.dialog = false;
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/task/'+this.edited_item.id, data: {}, method: 'DELETE', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          // this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Ununięto zadanie",
            color: "primary",
            btn_color: "white"
          });
          // this.user_data = resp.data.user_assign;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
          // Wymuś pobranie nowych danych
          this.$emit('update_me');
        })
    }
  },
  mounted(){
    // this.$store.commit('loader');

    // this.$store.commit('loader_off');
  }
};
</script>

<style lang="scss" scoped>
.acent {
  background-color: #333;
  color: white;
  &:hover {
    background-color: #444 !important;
  }
}
.change_card {
  background-color: rgb(112, 112, 112);
  color: white;
}
</style>