<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <!-- <v-switch
                  v-model="sprint_status"
                  @click="close_sprint()"
                  inset
                  :label="'Status: ' + (sprint_status?'Zamknięty':'Otwarty')"
                  style="margin-right: 15px"
                ></v-switch> -->
                <div
                  v-if="$store.state.user_permissions.project_sprint_update"
                >
                  <v-btn
                    color="secondary_dark"
                    text
                    @click="open_dialog_status=true"
                  >
                    STATUS: {{ sprint.status }}
                  </v-btn>
                  <v-btn
                    color="secondary_dark"
                    dark
                    depressed
                    tile
                    :to="'/task-nowy/'+id"
                  >
                    Dodaj zadanie
                  </v-btn>
                </div>
                <v-btn
                  color="secondary"
                  depressed
                  tile
                  :to="'/sprint-edytuj/'+id"
                  @click="dialog"
                  v-if="$store.state.user_permissions.project_sprint_update"
                >
                  Edytuj sprint
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>

        <v-row>

          <v-col cols="12" md="4">
            <Task 
              title="Zadania: nowe"
              :items="list.new"
              :users="list_of_users"
              @update_me="reload_table()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <Task 
              title="Zadania: W trakcie"
              :items="list.comming"
              :users="list_of_users"
              @update_me="reload_table()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <Task 
              title="Zadania: Zakończone"
              :items="list.end"
              :users="list_of_users"
              @update_me="reload_table()"
            />
          </v-col>
          
        </v-row>

      </v-col>
    </v-row>

    <v-dialog
      v-model="open_dialog_status"
      width="500"
      persistent
    >
      <v-card tile>
        <v-card-title class="change_card">
          Zmiana statusu
        </v-card-title>

        <v-card-text class="mt-2">
          <v-slider
            v-model="status_selected"
            :tick-labels="status_list"
            :max="2"
            step="1"
            ticks="always"
            tick-size="3"
            color="#333"
          ></v-slider>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="open_dialog_status=false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            tile
            depressed
            @click="update_status()"
          >
            Aktualizuj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
// #TODO zmiana wygladu - NIE TRELLO
import Task from '@/components/Projects/Task.vue';
export default {
  components: {
    Task
  },
  data: () => ({
    id: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt',
      },
      {
        text: 'Etap',
        disabled: true,
        to: '/sprint',
      },
    ],

    list: {
      new: [],
      comming: [],
      end: []
    },

    sprint: {
      tasks: []
    },

    list_of_users: [],

    open_dialog_status: false,
    status_list: ['Nowy','W trakcie','Zakończony'],
    status_selected: 0,
  }),
  methods: {
    get_data(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/sprint_task/'+this.id, data: {}, method: 'GET' })
        .then(resp => {
          this.sprint = resp.data.sprint;
          this.list.new = resp.data.New;
          this.list.comming = resp.data.Comming;
          this.list.end = resp.data.End;
          this.list_of_users = resp.data.project_user;
          this.breadcrumb[2].to = '/projekt/'+this.sprint.project_id;

          // Podmiana głownego statusu etapu
          console.log(this.sprint.status);
          if(this.sprint.status == 'Nowy') this.status_selected = 0;
          else if(this.sprint.status == 'W trakcie') this.status_selected = 1;
          else this.status_selected = 2;

          // Aktualizacja statusu
          // if(this.sprint.status == 'Nowy') this.sprint_status = false
          // else this.sprint_status = true;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    update_status(){
      this.open_dialog_status = false;
      // console.log(this.status_selected);

      this.$store.commit('loader');

      let formData = new FormData();

      if(this.status_selected == 2) {
        formData.append("status", 'Zakończony');
        this.sprint.status = 'Zakończony';
      }
      else if(this.status_selected == 1) {
        formData.append("status", 'W trakcie');
        this.sprint.status = 'W trakcie';
      }
      else {
        formData.append("status", 'Nowy');
        this.sprint.status = 'Nowy';
      }

      this.$axios({url: this.$store.state.api +'/sprint/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          // this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Zaaktualizowano status",
            color: "primary",
            btn_color: "white"
          });
          // this.user_data = resp.data.user_assign;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
        })
    },
    reload_table(){
      // Pobieranie nowych danych z serwera bo zmieniono status
      // #TODO może wypieprzyc pobieranie i samemu zmieniac po froncie
      this.get_data();
    },
    close_sprint(){
      this.$store.commit('loader');

      let formData = new FormData();
      // console.log(this.sprint_status);
      // if(this.sprint_status) formData.append("status", 'Zakończony');
      // else formData.append("status", 'Otwarty');

      this.$axios({url: this.$store.state.api +'/sprint/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          // this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Zaaktualizowano Etap",
            color: "primary",
            btn_color: "white"
          });
          // this.user_data = resp.data.user_assign;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.id = this.$route.params.id;

    this.get_data();
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>